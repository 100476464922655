var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ld-container",staticStyle:{"height":"90vh","width":"100%"}},[_c('div',[_c('header-box-component',{attrs:{"title":`${_vm.$t('건물 운영 관리')} > ${_vm.$t(
        `${
          _vm.$route.params.type == 'energy'
            ? '건물 에너지 운영 현황'
            : '계통 및 설비 운영 현황'
        }`
      )}`}})],1),_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"row"},_vm._l((_vm.sysNodeRootList),function(node){return _c('div',{key:node.sysNodeIdx,staticClass:"col-lg-4 col-md-6 col-12 mb-3 pointer-cursor",on:{"click":function($event){return _vm.goToDetail(node.sysNodeIdx)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"card-left"},[_c('span',{staticClass:"card-title mb-0",staticStyle:{"font-size":"2rem"}},[_c('i',{class:node.icon ?? ''})]),_c('span',{staticClass:"card-title mb-0",staticStyle:{"font-weight":"700","font-size":"0.9rem"}},[_vm._v(_vm._s(node.sysType ?? "-"))])]),_c('div',{staticClass:"card-right"},[_c('span',{staticClass:"card-title",staticStyle:{"font-weight":"600","font-size":"0.9rem"}},[_vm._v(_vm._s(node.sysTypeName ?? "-"))]),_c('h6',{staticClass:"card-subtitle text-muted"},[_vm._v(" "+_vm._s(node.description ?? "-")+" ")])]),_c('div',{staticClass:"system-stats"},[_c('span',{staticClass:"badge badge-secondary"},[_vm._v(_vm._s(`Root: ${node.RootCnt ?? 0}`))]),_c('span',{staticClass:"badge badge-secondary"},[_vm._v(_vm._s(`Group: ${node.GroupCnt ?? 0}`))]),_c('span',{staticClass:"badge badge-primary"},[_vm._v(_vm._s(`Equip: ${node.EquipCnt ?? 0}`))])])])])])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }